// types
import type { NavigationMenuItem } from '@revolutionprep/types'

export function useMenuItems () {
  // computed
  const menuItems = computed((): NavigationMenuItem[] => {
    return [
      {
        id: 'navItemDashboard',
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        to: '/',
        external: false
      },
      {
        id: 'navItemSchedule',
        title: 'Schedule',
        icon: 'mdi-calendar-month',
        to: '/schedule',
        external: false
      },
      {
        id: 'navItemProfile',
        title: 'Profile',
        icon: 'mdi-account-box-multiple',
        to: '/profile',
        external: false
      }
    ]
  })

  return {
    menuItems
  }
}
